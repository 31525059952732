import React from "react";
import styled from "styled-components";

import {
  TopBanner,
  BannerContentContainer,
  ImageContainer,
  ValuesCard,
} from "../home";
import Container from "../../Container";
import Card from "../../Card";
import Slider from "../../Slider";
import {
  RalewayBold,  
  RalewayRegular,
} from "../../../components/Text";
import { Colors } from "../../../constants/Colors";
import { GalleryArrowType } from "../../../constants/enums";
import { BreakpointValues } from "../../../constants/Breakpoints";
import ContainerWrapper from "../../ContainerWrapper";

import { mediaHelper } from "../../../utils/media-helper";
import { useWindowSize } from "../../../helpers/window-resize";

import ClientImg from "../../../assets/img/manifest/clients.png";
import ClientImg2x from "../../../assets/img/manifest/clients@2x.png";
import ClientImg3x from "../../../assets/img/manifest/clients@3x.png";
import ClientImg4x from "../../../assets/img/manifest/clients@4x.png";

import PriorizationImg from "../../../assets/img/manifest/priorization.png";
import PriorizationImg2x from "../../../assets/img/manifest/priorization@2x.png";
import PriorizationImg3x from "../../../assets/img/manifest/priorization@3x.png";
import PriorizationImg4x from "../../../assets/img/manifest/priorization@4x.png";

import ValidationImg from "../../../assets/img/manifest/validation.png";
import ValidationImg2x from "../../../assets/img/manifest/validation@2x.png";
import ValidationImg3x from "../../../assets/img/manifest/validation@3x.png";
import ValidationImg4x from "../../../assets/img/manifest/validation@4x.png";

import Up from "../../../assets/img/manifest/up.png";
import Up2x from "../../../assets/img/manifest/up@2x.png";
import Up3x from "../../../assets/img/manifest/up@3x.png";
import Up4x from "../../../assets/img/manifest/up@4x.png";

import Down from "../../../assets/img/manifest/down.png";
import Down2x from "../../../assets/img/manifest/down@2x.png";
import Down3x from "../../../assets/img/manifest/down@3x.png";
import Down4x from "../../../assets/img/manifest/down@4x.png";

import Lines from "../../../assets/img/lines.png";
import Lines2x from "../../../assets/img/lines@2x.png";
import Lines3x from "../../../assets/img/lines@3x.png";
import Lines4x from "../../../assets/img/lines@4x.png";

const ManifestTopBanner = styled(TopBanner)`
  display: flex;
  align-items: center;
  justify-content: center;


  && > img {
    position: absolute;

    position: absolute;
    bottom: 350px;
    left: -140px;
    width: 80%;

    ${mediaHelper.es`
      left: -160px;
      width: 60%;      
    `}

    ${mediaHelper.sm`
      bottom: 200px;
    `}

    ${mediaHelper.md`
      width: inherit;
      bottom: 62px;    
    `}
  }

`;

const ManifestBannerContainer = styled(BannerContentContainer)`
  display: flex;
  align-items: center;
  background-color: transparent;

  & > div > h2 {
    opacity: 1;
  }

  ${mediaHelper.md`    
    margin: 0;
    padding: 100px 0;
  `};

  ${mediaHelper.lg`    
    padding: 0;
  `};

   
  & > div > h2 {
    ${RalewayBold}
    color: white;
    font-size: 16px;
    line-height: 159.4%;
    letter-spacing: 0.075em;
    text-transform: uppercase;
  }

  & > div > h1 {
    letter-spacing: 0.03em;
    color: white;
    text-transform: initial;
    opacity: 1;
    font-size: 27px;
    line-height: 42px;

    ${mediaHelper.md`    
      font-size: 40px;
      line-height: 64px;
    `};
  }

  & > div > p {
    ${RalewayRegular}
    color: white;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    padding-left: 27px;
    margin-top: 40px;
    border-left: 1px solid white;
    margin-bottom: 40px;

    ${mediaHelper.lg`    
      margin-bottom: 64px;
    `};
  }
`;

const CardsSection = styled(Container)`
  margin-top: -122px;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  max-width: inherit;

  ${mediaHelper.lg`
    margin-top: -88px;  
    margin-left: auto;
    margin-right: auto; 
    padding: 0;
    max-width: 960px;
  `};

  ${mediaHelper.xl`    
    max-width: 1140px;    
  `};

  ${mediaHelper.xxl`    
    max-width: 1240px;    
  `};
`;

const CapacityExplanation = styled(Container)`
  ${mediaHelper.md`
    margin-top: 61px;
  `}

  p {
    ${RalewayRegular}
    color: ${Colors.GRAY};
    font-size: 16px;
    line-height: 26px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  margin-top: -88px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.08);
`;

const ManifestCard = styled(ValuesCard)`
  margin-left: 25px;
  display: flex;
  min-height: 442px;
  flex-direction: column;

  ${mediaHelper.md`
    min-height: 368px;
  `}

  ${mediaHelper.lg`        
    min-height: inherit;
    box-shadow: none;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    flex: 1;
  `};

  & ${ImageContainer} {
    height: 90px;
    & div {
      height: 100%;

      img {
        width: 100%;
      }
    }
  }

  &:nth-child(3) {
    & ${ImageContainer} {
      & div {
        img {
          height: 100%;
          width: initial;
        }
      }
    }
  }
`;

const ActingMomentSection = styled(Container)`
  background-color: ${Colors.LIGHTEST_GRAY};
  position: relative;

  ${mediaHelper.md`        
    padding-top: 81px;    
    padding-bottom: 40px;
  `};

  h2 {
    ${RalewayBold}
    color: ${Colors.DARK};
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.075em;
    text-transform: uppercase;    
  }

  & > p {
    ${RalewayRegular}
    color: ${Colors.GRAY};
    font-size: 16px;
    line-height: 26px;
    margin-top: 56px;
    position: relative;
    z-index: 100;
  }

  & > img {
    position: absolute;
    bottom: 0;
    left: -340px;
    transform: scaleX(-1);
    display: none;

    ${mediaHelper.md`        
      display: block;
    `};
  }
`;

const CircleContainer = styled.div`
  background-color: ${Colors.LIGHTEST_GRAY};
  display: flex;
  flex-direction: column;

  ${mediaHelper.sm`    
    padding: 0 40px;
  `};

  ${mediaHelper.md`    
      padding: 0 150px;
  `};

  ${mediaHelper.lg`    
    padding: 0;
    flex-direction: row;
  `};
`;

const ActingCircle = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 1000px;
  border: 2.1375px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ down }) => (down ? "auto" : "0")};
  margin-top: 0;

  ${mediaHelper.lg`    
    width: 180px;
    height: 180px;
    margin-top: ${({ down }) => (down ? "100px" : "0")};
  `};

  div {
    max-width: 156px;
    text-align: center;

    h3 {
      ${RalewayBold};
      color: ${Colors.DARK};
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      ${RalewayRegular};
      color: ${Colors.DARK};
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const ArrowContainer = styled.div`
  width: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: -20px;
  margin-left: auto;
  margin-right: auto;
  transform: ${({ up }) => up && "rotate(180deg)"};

  ${mediaHelper.lg` 
    transform: rotate(0);
    margin-top: 0;
    margin-bottom: 0;
    width: 20px;
  `};
`;

const Manifest = ({ props }) => {
  const currentWindowSize = useWindowSize();
  const isDesktop = currentWindowSize.width >= BreakpointValues.lg;

  return (
    <>
      <ManifestTopBanner id="page-top">
        <img
          alt="lines"
          srcSet={`
            ${Lines4x} 4x,
            ${Lines3x} 3x,
            ${Lines2x} 2x,
            ${Lines} 1x`}
          src={Lines}
        />
        <ManifestBannerContainer>
          <div>
            <h2>Manifesto</h2>
            <h1>Os processos são, hoje, as verdadeiras soluções.</h1>
            <p>
              Empresas da chamada "nova economia" construíram, como o grande diferencial competitivo, processos embasados em dados provindos diretamente dos clientes. Esses processos, com foco na real experiência dos consumidores e não na percepção dos executivos, geraram um outro dinamismo no mercado, proporcionando um ritmo acelerado na evolução dos produtos e serviços.<br />
              <br />
              Nós, da Wefit, acreditamos que são justamente as empresas já estabelecidas que, de fato, serão os agentes dessa revolução, do movimento chamado de "transformação digital", desde que transformem seu processo decisório, saindo da base da percepção e assumindo os fatos: a experiência dos clientes.
              <br />
              <br />
              Será muito mais produtivo para as empresas, e para as sociedades, aproveitar os ecossistemas já existentes para desenvolver novos negócios, do que partir do zero para validação de cada nova hipótese.
            </p>
          </div>
        </ManifestBannerContainer>
      </ManifestTopBanner>
      <ContainerWrapper>
        <CardsSection>
          {isDesktop ? (
            <CardsContainer>
              <ManifestCard>
                <ImageContainer>
                  <div style={{ width: 110 }}>
                    <img
                      alt="clientes"
                      srcSet={`
                    ${ClientImg4x} 4x,
                    ${ClientImg3x} 3x,
                    ${ClientImg2x} 2x,
                    ${ClientImg} 1x`}
                      src={ClientImg}
                    />
                  </div>
                </ImageContainer>
                <div>
                  <h2>Conexão com os Clientes</h2>
                  <p>
                    Transformando os dados gerados pelos consumidores em informações estruturadas. Alinhando propósitos e integrando necessidades das diferentes áreas operacionais, mitigando os hiatos e diminuindo os desgastes (tempo e relacionamento) entre negócios, marketing e tecnologia.
                  </p>
                </div>
              </ManifestCard>
              <ManifestCard>
                <ImageContainer>
                  <div style={{ width: 137 }}>
                    <img
                      alt="priorization"
                      srcSet={`
                    ${PriorizationImg4x} 4x,
                    ${PriorizationImg3x} 3x,
                    ${PriorizationImg2x} 2x,
                    ${PriorizationImg} 1x`}
                      src={PriorizationImg}
                    />
                  </div>
                </ImageContainer>
                <div>
                  <h2>Priorização (projetos x processos)</h2>
                  <p>
                    A visão de projetos é uma barreira na evolução dos produtos/serviços, pois geralmente é inviável tratar todos os problemas ao mesmo tempo, com prazo e custo determinados. A partir de uma priorização dos problemas/oportunidades, conjuntamente com indicadores e parâmetros reais, é possível conquistar mais velocidade e precisão nas iniciativas das empresas.
                  </p>
                </div>
              </ManifestCard>
              <ManifestCard>
                <ImageContainer>
                  <div style={{ width: 60 }}>
                    <img
                      alt="validation"
                      srcSet={`
                    ${ValidationImg4x} 4x,
                    ${ValidationImg3x} 3x,
                    ${ValidationImg2x} 2x,
                    ${ValidationImg} 1x`}
                      src={ValidationImg}
                    />
                  </div>
                </ImageContainer>
                <div>
                  <h2>Validações</h2>
                  <p>
                    Mudar a forma de testar novas ideias. Ao invés de construir novas soluções, o objetivo precisa ser encontrar como medir a percepção de valor dos clientes com o menor investimento possível. Inevitavelmente, o planejado é diferente do realizado.
                  </p>
                </div>
              </ManifestCard>
            </CardsContainer>
          ) : (
            <Slider
              className="overlay"
              mobileCardNum={1.2}
              tabletCardNum={1.2}
              lapTopCardNum={1.2}
              cardNum={1.2}
              arrowPos="-5px"
              navColor={GalleryArrowType.GRAY}
            >
              <Card noStyle duration={500}>
                <ManifestCard>
                  <ImageContainer>
                    <div style={{ width: 110 }}>
                      <img
                        alt="clients"
                        srcSet={`
                          ${ClientImg4x} 4x,
                          ${ClientImg3x} 3x,
                          ${ClientImg2x} 2x,
                          ${ClientImg} 1x`}
                        src={ClientImg}
                      />
                    </div>
                  </ImageContainer>
                  <div>
                    <h2>Conexão com os Clientes</h2>
                    <p>
                      Transformando os dados gerados pelos consumidores em informações estruturadas. Alinhando propósitos e integrando necessidades das diferentes áreas operacionais, mitigando os hiatos e diminuindo os desgastes (tempo e relacionamento) entre negócios, marketing e tecnologia.
                    </p>
                  </div>
                </ManifestCard>
              </Card>
              <Card noStyle duration={500}>
                <ManifestCard>
                  <ImageContainer>
                    <div style={{ width: 137 }}>
                      <img
                        alt="priorization"
                        srcSet={`
                    ${PriorizationImg4x} 4x,
                    ${PriorizationImg3x} 3x,
                    ${PriorizationImg2x} 2x,
                    ${PriorizationImg} 1x`}
                        src={PriorizationImg}
                      />
                    </div>
                  </ImageContainer>
                  <div>
                    <h2>Priorização (projetos x processos)</h2>
                    <p>
                      A visão de projetos é uma barreira na evolução dos produtos/serviços, pois geralmente é inviável tratar todos os problemas ao mesmo tempo, com prazo e custo determinados. A partir de uma priorização dos problemas/oportunidades, conjuntamente com indicadores e parâmetros reais, é possível conquistar mais velocidade e precisão nas iniciativas das empresas.
                    </p>
                  </div>
                </ManifestCard>
              </Card>
              <Card noStyle duration={500}>
                <ManifestCard>
                  <ImageContainer>
                    <div style={{ width: 60 }}>
                      <img
                        style={{ height: "100%", width: "auto" }}
                        alt="validation"
                        srcSet={`
                    ${ValidationImg4x} 4x,
                    ${ValidationImg3x} 3x,
                    ${ValidationImg2x} 2x,
                    ${ValidationImg} 1x`}
                        src={ValidationImg}
                      />
                    </div>
                  </ImageContainer>
                  <div>
                    <h2>Validações</h2>
                    <p>
                      Mudar a forma de testar novas ideias. Ao invés de construir novas soluções, o objetivo precisa ser encontrar como medir a percepção de valor dos clientes com o menor investimento possível. Inevitavelmente, o planejado é diferente do realizado.
                    </p>
                  </div>
                </ManifestCard>
              </Card>
            </Slider>
          )}
        </CardsSection>
        <CapacityExplanation>
          <p>
            Portanto, no nosso entendimento, o grande desafio é construir processos contemplando todos os pontos acima destacados. Inovar começando pelo cliente e trabalhando de trás para frente.
            <br />
            <br />
            Qualquer atitude pontual, seja em uma atividade ou área específica (big data, design), será irrelevante, pois o grande ativo no final é a continuidade, o engajamento entre as áreas, com foco único nas dores dos clientes e apoiados pelo plano estratégico das empresas.
          </p>
        </CapacityExplanation>
      </ContainerWrapper>
      <ContainerWrapper>
        <ActingMomentSection>
          <h2>Momento de atuação</h2>
          <CircleContainer>
            <ActingCircle up>
              <div>
                <h3>Pesquisas</h3>
                <p>Levantamento e organização das dores e oportunidades</p>
              </div>
            </ActingCircle>
            <ArrowContainer>
              <img
                alt="down"
                srcSet={`
                      ${Down4x} 4x,
                      ${Down3x} 3x,
                      ${Down2x} 2x,
                      ${Down} 1x`}
                src={Down}
              />
            </ArrowContainer>
            <ActingCircle down>
              <div>
                <h3>Priorização</h3>
                <p>A partir das dores e oportunidades organizadas</p>
              </div>
            </ActingCircle>
            <ArrowContainer up>
              <img
                alt="up"
                srcSet={`
                      ${Up4x} 4x,
                      ${Up3x} 3x,
                      ${Up2x} 2x,
                      ${Up} 1x`}
                src={Up}
              />
            </ArrowContainer>
            <ActingCircle up>
              <div>
                <h3>Design</h3>
                <p>Protótipos, interfaces, testes e validação</p>
              </div>
            </ActingCircle>
            <ArrowContainer>
              <img
                alt="down"
                srcSet={`
                      ${Down4x} 4x,
                      ${Down3x} 3x,
                      ${Down2x} 2x,
                      ${Down} 1x`}
                src={Down}
              />
            </ArrowContainer>
            <ActingCircle down>
              <div>
                <h3>Dev</h3>
                <p>Desenvolvimento das interfaces e experiências desenhadas</p>
              </div>
            </ActingCircle>
            <ArrowContainer up>
              <img
                alt="up"
                srcSet={`
                      ${Up4x} 4x,
                      ${Up3x} 3x,
                      ${Up2x} 2x,
                      ${Up} 1x`}
                src={Up}
              />
            </ArrowContainer>
            <ActingCircle up>
              <div>
                <h3>Medição</h3>
                <p>Monitoramento e avaliação do desempenho das soluções</p>
              </div>
            </ActingCircle>
          </CircleContainer>
          <p>
            Focando nesse cenário de integração, engajamento e continuidade, trabalhamos nos últimos anos em uma metodologia que não desconstruísse os processos atuais das empresas, mas sim ajudasse na evolução dos mesmos: aproveitando as competências já desenvolvidas e aprimorando outras que tenham menor eficiência. Desenvolvendo novas pontes nas relações entre os clientes, áreas e pessoas, dentro de um único trabalho
          </p>
          <img
            alt="lines"
            srcSet={`
              ${Lines4x} 4x,
              ${Lines3x} 3x,
              ${Lines2x} 2x,
              ${Lines} 1x`}
            src={Lines}
          />
        </ActingMomentSection>
      </ContainerWrapper>
    </>
  );
};

export default Manifest;
