// Gatsby supports TypeScript natively!
import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Manifest from "../components/flows/manifest"

import MetaImage from "../assets/img/seo/manifesto-wefit-data-driven-design.png"

const SecondPage = (props) => {
  const { location } = props;

  return (
    <Layout originPage="Manifesto" location={location}>
      <SEO
        title="WeFit"
        location={location}
        description="Os processos são, hoje, as verdadeiras soluções."
        image={{
          src: MetaImage,
          height: 1080,
          width: 1920,
        }}
      />
      <Manifest></Manifest>
    </Layout>
  )
}

export default SecondPage
